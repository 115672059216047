import React, { useEffect, useRef } from 'react'
import styles from './index.module.scss'
import Player, { PlayerRef } from '@/components/player'
import dayjs from 'dayjs'
import { Events } from 'xgplayer'
const TestPage = () => {
  const playerRef = useRef<PlayerRef>(null)

  useEffect(() => {
    const player = playerRef?.current?.player
    if (player) {
      player.on(Events.PLAY, () => {
        console.info('play', dayjs().format('YYYY-MM-DD HH:mm:ss'))
      })
      player.on(Events.PLAYING, () => {
        console.info('PLAYING', dayjs().format('YYYY-MM-DD HH:mm:ss'))
      })
      player.on(Events.SEEKING, () => {
        console.info('SEEKING', dayjs().format('YYYY-MM-DD HH:mm:ss'))
      })
      player.on(Events.SEEKED, () => {
        console.info('SEEKED', dayjs().format('YYYY-MM-DD HH:mm:ss'))
      })
      player.on(Events.CANPLAY, () => {
        console.info('CANPLAY', dayjs().format('YYYY-MM-DD HH:mm:ss'))
      })
      player.on(Events.CANPLAY_THROUGH, () => {
        console.info('CANPLAY_THROUGH', dayjs().format('YYYY-MM-DD HH:mm:ss'))
      })
      player.on(Events.DURATION_CHANGE, () => {
        console.info('DURATION_CHANGE', dayjs().format('YYYY-MM-DD HH:mm:ss'))
      })
      player.on(Events.LOADED_DATA, () => {
        console.info('LOADED_DATA', dayjs().format('YYYY-MM-DD HH:mm:ss'))
      })
      player.on(Events.LOADED_METADATA, () => {
        console.info('LOADED_METADATA', dayjs().format('YYYY-MM-DD HH:mm:ss'))
      })
      player.on(Events.PROGRESS, () => {
        console.info('PROGRESS', dayjs().format('YYYY-MM-DD HH:mm:ss'))
      })
      player.on(Events.LOAD_START, () => {
        console.info('LOAD_START', dayjs().format('YYYY-MM-DD HH:mm:ss'))
      })
      player.on(Events.STALLED, () => {
        console.info('STALLED', dayjs().format('YYYY-MM-DD HH:mm:ss'))
      })

      player.on(Events.SUSPEND, () => {
        console.info('SUSPEND', dayjs().format('YYYY-MM-DD HH:mm:ss'))
      })
      player.on(Events.READY, () => {
        console.info('READY', dayjs().format('YYYY-MM-DD HH:mm:ss'))
      })
    }
  }, [playerRef.current?.player])
  return (
    <div className={styles.container}>
      <h1>Native 测试页面</h1>
      <Player
        ref={playerRef}
        video={{
          id: 554616,
          horizontal_cover:
            'https://video-photo.121382.com/20240404/40827d2fd3804265aee490cbcc13eeee.webp?auth_key=1715517058-30205f48ba4041df83f02664cbdf2fef-0-031aa85d6093b8cf6e7a2908c7604c64'
        }}
        options={{
          id: `test${554616}`,
          videoInit: true,
          controls: {
            autoHide: false,
            mode: 'bottom',
            initShow: true
          },
          loop: false,
          autoplay: false
        }}
        onDurationchange={() => {
          console.info('组件内部', 'onDurationchange')
        }}
        onAutoPlayStarted={() => {
          console.info('组件内部', 'onAutoPlayStarted')
        }}
      />
    </div>
  )
}
export default TestPage
