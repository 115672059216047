import React from 'react'
import styles from './index.module.scss'
const TestPage = () => {
  return (
    <div className={styles.container}>
      <h1>Native 测试页面</h1>
      <h2>uuid:{window?.native?.deviceId}</h2>
      <h2>platform:{window?.native?.platform}</h2>
      <h2>channelCode:{window?.native?.channelCode}</h2>
      <div>
        <h2 style={{ color: 'red', fontSize: 13 }}>
          window.native.openUrl(url,type) 1、内部跳转，2、外部跳转
        </h2>
        <button
          onClick={() => {
            if (window?.native?.openUrl) {
              window.native.openUrl('https://baidu.com', 1)
            }
          }}
        >
          type 1 内部跳转
        </button>
        <button
          onClick={() => {
            if (window?.native?.openUrl) {
              window.native.openUrl('https://baidu.com', 2)
            }
          }}
        >
          type 1 内部跳转
        </button>
      </div>

      <div>
        <h2 style={{ color: 'red', fontSize: 14 }}>
          window.native.getDeviceId() 获取唯一设备号
        </h2>
        <button
          onClick={() => {
            if (window?.native?.getDeviceId) {
              window.native.getDeviceId()
            }
          }}
        >
          获取唯一设备号
        </button>
      </div>

      <div>
        <h2 style={{ color: 'red', fontSize: 14 }}>
          window.native.getChannelCode() 获取渠道号
        </h2>
        <button
          onClick={() => {
            if (window?.native?.getChannelCode) {
              window.native.getChannelCode()
            }
          }}
        >
          获取渠道号
        </button>
      </div>

      <div>
        <h2 style={{ color: 'red', fontSize: 14 }}>
          window.native.getPlatform() 获取平台信息 android/ios
        </h2>
        <button
          onClick={() => {
            if (window?.native?.getPlatform) {
              window.native.getPlatform()
            }
          }}
        >
          获取平台信息
        </button>
      </div>

      <div>
        <h2 style={{ color: 'red', fontSize: 14 }}>
          window.native.getStatusBarHeight() 获取头部安全区域高度
        </h2>
        <button
          onClick={() => {
            if (window?.native?.getStatusBarHeight) {
              window.native.getStatusBarHeight()
            }
          }}
        >
          获取头部安全区域高度
        </button>
      </div>

      <div>
        <h2 style={{ color: 'red', fontSize: 14 }}>
          window.native.hideBottomBar() 隐藏底部操作按钮
        </h2>
        <button
          onClick={() => {
            if (window?.native?.hideBottomBar) {
              window.native.hideBottomBar()
            }
          }}
        >
          隐藏底部操作按钮
        </button>
      </div>

      <div>
        <h2 style={{ color: 'red', fontSize: 14 }}>
          window.native.showBottomBar() 显示底部操作按钮
        </h2>
        <button
          onClick={() => {
            if (window?.native?.showBottomBar) {
              window.native.showBottomBar()
            }
          }}
        >
          显示底部操作按钮
        </button>
      </div>

      <div>
        <h2 style={{ color: 'red', fontSize: 14 }}>
          window.native.setBottomBarColor() 设置底部按钮背景色
        </h2>
        <button
          onClick={() => {
            if (window?.native?.setBottomBarColor) {
              window.native.setBottomBarColor('#ff0000')
            }
          }}
        >
          设置底部按钮背景色#ff0000
        </button>
      </div>

      <div>
        <h2 style={{ color: 'red', fontSize: 14 }}>
          window.native.exitApp() 退出应用
        </h2>
        <button
          onClick={() => {
            if (window?.native?.exitApp) {
              window.native.exitApp()
            }
          }}
        >
          退出应用
        </button>
      </div>
    </div>
  )
}
export default TestPage
