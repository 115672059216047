import XgPlayer, { Util, type IPlayerOptions } from 'xgplayer'
import HlsPlayer from 'xgplayer-hls.js'
import styles from './index.module.scss'
import qs from 'querystring'
import 'xgplayer/dist/index.min.css'
import { getBaseReq } from '@/utils/session'
import { BaseVideo } from '@/interfaces/common'
import { merge } from 'lodash'
import AutoplayPreventedPlugin from './plugins/prevented/index'
import ReplayPlugin from './plugins/replay/index'

const { themes } =
  require(`@/themes/${process.env.NEXT_PUBLIC_PLATFORM}/config`).default

const playIcon = `<div class='${styles.playIcon}'>
<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
<g filter="url(#filter0_b_207_1594)">
<path d="M32 0C14.3267 0 0 14.3267 0 32C0 49.6733 14.3267 64 32 64C49.6733 64 64 49.6733 64 32C64 14.3267 49.6733 0 32 0Z" fill="currentColor"/>
</g>
<path d="M41.3801 34.7207L27.8749 45.5783C27.3809 45.8545 26.8205 46 26.2501 46C25.6796 46 25.1193 45.8546 24.6252 45.5785C24.1312 45.3023 23.7209 44.905 23.4356 44.4266C23.1503 43.9482 23.0001 43.4055 23 42.8531V21.1469C23.0001 20.5945 23.1503 20.0518 23.4356 19.5734C23.7209 19.095 24.1312 18.6977 24.6252 18.4215C25.1193 18.1454 25.6796 18 26.2501 18C26.8205 18 27.3809 18.1455 27.8749 18.4217L41.3801 29.2748C41.8728 29.5514 42.2818 29.9485 42.5661 30.4264C42.8504 30.9043 43 31.4462 43 31.9977C43 32.5493 42.8504 33.0911 42.5661 33.569C42.2818 34.0469 41.8728 34.4441 41.3801 34.7207Z" fill="white"/>
<defs>
<filter id="filter0_b_207_1594" x="-6" y="-6" width="76" height="76" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="3"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_207_1594"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_207_1594" result="shape"/>
</filter>
</defs>
</svg>
</div>
`

export const getVideoSrc = (
  videoId: number
  // origin = process.env.NEXT_PUBLIC_CANONICAL_URL
): string => {
  const path = '/api/v2/video/player.m3u8'
  const payload = {
    vid: videoId,
    ...getBaseReq()
  }
  return `${path}?${qs.stringify(payload)}`
}

export const createPlayer = async (
  video: BaseVideo,
  el: HTMLElement,
  ops?: IPlayerOptions
): Promise<XgPlayer | null> => {
  const url = getVideoSrc(video.id)
  // const poster = await getDecryptImage(video.horizontal_cover)

  const options: IPlayerOptions = merge(
    {
      // poster,
      width: '100%',
      height: '100%',
      'x5-video-player-type': 'h5',
      playsinline: true,
      // autoplayMuted: true,
      el,
      url,
      icons: {
        startPlay: Util.createDom('div', playIcon, {}, 'play xg-icon-play'),
        startPause: Util.createDom(
          'div',
          playIcon,
          { class: 'pushIcons' },
          'pause xg-icon-pause'
        )
      },
      commonStyle: {
        // 播放完成部分进度条底色
        playedColor: themes.primaryColor,
        // 进度条滑块样式
        sliderBtnStyle: {
          background: 'none',
          boxShadow: 'none'
        },
        // 音量颜色
        volumeColor: '#00b781'
      }
    },
    ops
  )
  return new XgPlayer({
    ...options,
    ignores: ['replay'],
    plugins: [HlsPlayer, AutoplayPreventedPlugin, ReplayPlugin],
    useHls: true
  })
}
