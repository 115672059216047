import React, { Suspense, lazy } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import NotFoundPage from './pages/notFound'
import LoadingPage from './components/loadingPage'
import PageLayout from '@/components/layout'
import { ROUTES } from '@/constants/routers'
import KeepAlive from 'react-activation'
import TestPage from './pages/test'
import TestVideoPage from './pages/testVideo'
import './themes/rest.scss'
import { InitRouterDataRes } from './interfaces/common'

// 首页的路由需要先预加载文件
const HomePromise = import('./pages/home')
const HomePage = lazy(() => HomePromise)

const VideosPromise = import('./pages/videos')
const VideosPage = lazy(() => VideosPromise)

const AnwangPromise = import('./pages/anwang')
const AnwangPage = lazy(() => AnwangPromise)

const AppsPromise = import('./pages/apps')
const AppsPage = lazy(() => AppsPromise)

const BlogsPromise = import('./pages/blogs')
const BlogsPage = lazy(() => BlogsPromise)

const CirclePromise = import('./pages/circle')
const CirclePage = lazy(() => CirclePromise)

const BtsPromise = import('./pages/bts')
const BtsPage = lazy(() => BtsPromise)

const MyPromise = import('./pages/my')
const MyPage = lazy(() => MyPromise)
// 首页的路由需要先预加载文件 end

// 首页相关二级路由
const HomeVide = lazy(() => import('./pages/home/video/index'))
const HomeSearch = lazy(() => import('./pages/home/search/index'))
const HomeSearchRes = lazy(() => import('./pages/home/searchRes/index'))
const HomeSearchModule = lazy(() => import('./pages/home/module/index'))

// 短视频相关
const VideosPageUp = lazy(() => import('./pages/videos/up'))
const VideosPageUpVideo = lazy(() => import('./pages/videos/upVideo'))

// 社区相关
const BlogsArticleDetailPage = lazy(() => import('./pages/blogs/article'))
const BlogsCircleListPage = lazy(() => import('./pages/blogs/circle'))
const BlogsPublishPage = lazy(() => import('./pages/blogs/publish'))
const BlogsPublishSectionPage = lazy(
  () => import('./pages/blogs/publish/section')
)
// 社区相关 end

// 圈子相关
const CircleArticleDetailPage = lazy(() => import('./pages/circle/article'))
const CirclePublishPage = lazy(() => import('./pages/circle/publish'))
const CirclePublishSectionPage = lazy(
  () => import('./pages/circle/publish/section')
)
// 圈子相关 end

// 种子
const BtsDetailPage = lazy(() => import('./pages/bts/article/index'))

// 我的 start
const MyBuy = lazy(() => import('@/pages/my/buy'))
const MyCollect = lazy(() => import('@/pages/my/collect'))
const MyCollectShortVideo = lazy(() => import('@/pages/my/collect/shortVideo'))
const MyExchange = lazy(() => import('@/pages/my/exchange'))
const MyFeedback = lazy(() => import('@/pages/my/feedback'))
const MyInvite = lazy(() => import('@/pages/my/invite'))
const MyJoinGroup = lazy(() => import('@/pages/my/joinGroup'))
const MyLogin = lazy(() => import('@/pages/my/login'))
const MyMember = lazy(() => import('@/pages/my/member'))
const MyFocus = lazy(() => import('@/pages/my/myFocus'))
const MyOrder = lazy(() => import('@/pages/my/order'))
const MyPost = lazy(() => import('@/pages/my/post'))
const MyPromotion = lazy(() => import('@/pages/my/promotion'))
const MyPromotionRecord = lazy(() => import('@/pages/my/promotion/record'))
const MyPromotionRecordUseList = lazy(
  () => import('@/pages/my/promotion/record/useList')
)
const MyProxy = lazy(() => import('@/pages/my/proxy'))
const MySetting = lazy(() => import('@/pages/my/setting'))
const MyWallet = lazy(() => import('@/pages/my/wallet'))
// 我的 end

// 客服
const ServerPage = lazy(() => import('./pages/my/service'))

const RootComponent: React.FC<{ initRouterData: InitRouterDataRes }> = ({
  initRouterData
}) => {
  const location = useLocation()
  const homeRouterList = [
    {
      alias: 'videos',
      components: (
        <VideosPage videoListResDefault={initRouterData?.videoListResDefault} />
      )
    },
    {
      alias: 'anwang',
      components: <AnwangPage />
    },
    {
      alias: 'apps',
      components: <AppsPage appGlobalCenter={initRouterData?.appGlobalCenter} />
    },
    {
      alias: 'blogs',
      components: (
        <BlogsPage
          blogsNid={initRouterData?.blogsNid}
          blogsCircleDefault={initRouterData?.blogsCircleDefault}
          blogsNavigationDefault={initRouterData?.blogsNavigationDefault}
          blogsPostListDefault={initRouterData?.blogsPostListDefault}
        />
      )
    },
    {
      alias: 'my',
      components: <MyPage />
    },
    {
      alias: 'circle',
      components: (
        <CirclePage
          circleNid={initRouterData?.circleNid}
          circleCircleDefault={initRouterData?.circleCircleDefault}
          circleNavigationDefault={initRouterData?.circleNavigationDefault}
          circlePostListDefault={initRouterData?.circlePostListDefault}
        />
      )
    },
    {
      alias: 'bts',
      components: (
        <BtsPage
          btsNid={initRouterData?.btsNid}
          btsCircleDefault={initRouterData?.btsCircleDefault}
          btsNavigationDefault={initRouterData?.btsNavigationDefault}
          btsPostListDefault={initRouterData?.btsPostListDefault}
        />
      )
    }
  ]
  return (
    <Routes>
      {/* 首页路由 */}
      <Route
        element={
          <PageLayout
            isShowFooter={true}
            needLinearBg={true}
            needScroll={false}
          />
        }
      >
        <Route
          path={'/'}
          index
          element={
            <Suspense fallback={<LoadingPage />}>
              <KeepAlive
                style={{ height: '100%' }}
                cacheKey={`keep_alive_home`}
              >
                <HomePage />
              </KeepAlive>
            </Suspense>
          }
        />
      </Route>
      <Route
        element={
          <PageLayout
            isShowFooter={true}
            needLinearBg={true}
            needScroll={true}
          />
        }
      >
        {homeRouterList.map((item, index) => {
          return (
            <Route
              path={`/${item.alias}`}
              key={index}
              index
              element={
                <Suspense fallback={<LoadingPage />}>
                  <KeepAlive
                    style={{ height: '100%' }}
                    cacheKey={`keep_alive_${item.alias}`}
                  >
                    {item.components}
                  </KeepAlive>
                </Suspense>
              }
            />
          )
        })}
      </Route>
      {/* 首页路由 end */}
      {/* 其它页面的 模板路由*/}
      <Route
        element={
          <PageLayout
            isShowFooter={false}
            needLinearBg={false}
            needScroll={true}
          />
        }
      >
        {/* 种子详情 */}
        <Route
          path={ROUTES.BTS_DETAIL_ROUTE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <BtsDetailPage key={location.key} />
            </Suspense>
          }
        />
        {/* 首页短视频详情 */}
        <Route
          path={ROUTES.HOMEPAGE_VIDEO}
          element={
            <Suspense fallback={<LoadingPage />}>
              <HomeVide key={location.key} />
            </Suspense>
          }
        />
        {/* 首页搜索 */}
        <Route
          path={ROUTES.HOMEPAGE_SEARCH}
          element={
            <Suspense fallback={<LoadingPage />}>
              <HomeSearch />
            </Suspense>
          }
        />
        {/* 首页搜索结果页 */}
        <Route
          path={ROUTES.HOMEPAGE_SEARCH_RES}
          element={
            <Suspense fallback={<LoadingPage />}>
              <HomeSearchRes key={location.key} />
            </Suspense>
          }
        />
        {/* tag搜索页 */}
        <Route
          path={ROUTES.HOMEPAGE_SEARCH_MODULE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <KeepAlive cacheKey={`cacheKey_${location?.pathname}`}>
                <HomeSearchModule key={location.key} />
              </KeepAlive>
            </Suspense>
          }
        />
        {/* 社区详情 */}
        <Route
          path={ROUTES.BLOGS_DETAIL_ROUTE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <BlogsArticleDetailPage key={location.key} />
            </Suspense>
          }
        />
        {/* 社区圈子 */}
        <Route
          path={ROUTES.BLOGS_CIRCLE_ROUTE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <KeepAlive>
                <BlogsCircleListPage key={location.key} />
              </KeepAlive>
            </Suspense>
          }
        />
        {/* 社区发布 */}
        <Route
          path={ROUTES.BLOGS_PUBLISH_ROUTE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <KeepAlive cacheKey={`cacheKey_${location?.pathname}`}>
                <BlogsPublishPage />
              </KeepAlive>
            </Suspense>
          }
        />
        {/* 社区选择板块 */}
        <Route
          path={ROUTES.BLOGS_PUBLISH_SECTION_ROUTE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <BlogsPublishSectionPage />
            </Suspense>
          }
        />
        {/* 圈子详情 */}
        <Route
          path={ROUTES.CIRCLE_DETAIL_ROUTE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <CircleArticleDetailPage key={location.key} />
            </Suspense>
          }
        />
        {/* 圈子发布 */}
        <Route
          path={ROUTES.CIRCLE_PUBLISH_ROUTE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <KeepAlive cacheKey={`cacheKey_${location?.pathname}`}>
                <CirclePublishPage />
              </KeepAlive>
            </Suspense>
          }
        />
        {/* 圈子选择板块 */}
        <Route
          path={ROUTES.CIRCLE_PUBLISH_SECTION_ROUTE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <CirclePublishSectionPage />
            </Suspense>
          }
        />
        {/* ------------------- 我的像相关 start  ----------------------------------- */}
        <Route
          path={ROUTES.MY_BUY}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyBuy />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_COLLECT}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyCollect />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_COLLECT_SHORT_VIDEO}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyCollectShortVideo />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_EXCHANGE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyExchange />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_FEEDBACK}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyFeedback />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_INVITE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyInvite />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_JOIN_GROUP}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyJoinGroup />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_LOGIN}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyLogin />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_MEMBER}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyMember />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_FOCUS}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyFocus />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_ORDER}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyOrder />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_POST}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyPost />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_PROMOTION}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyPromotion />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_PROMOTION_RECORD}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyPromotionRecord />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_PROMOTION_RECORD_USELIST}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyPromotionRecordUseList />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_PROXY}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyProxy />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_SETTING}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MySetting />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.MY_WALLET}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MyWallet />
            </Suspense>
          }
        />
        {/* 我的像相关 end ---------------------------------------------------- */}
      </Route>
      {/* 其它页面的 模板路由*/}
      <Route element={<PageLayout isShowFooter={false} needLinearBg={false} />}>
        {/* 短视频up主 */}
        <Route
          path={ROUTES.VIDEOS_UP_ROUTE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <KeepAlive cacheKey={`cacheKey_${location?.pathname}`}>
                <VideosPageUp />
              </KeepAlive>
            </Suspense>
          }
        />
        {/* 短视频up主视频 */}
        <Route
          path={ROUTES.VIDEOS_UPVIDEO_ROUTE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <VideosPageUpVideo />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.SERVICE_ROUTE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <ServerPage />
            </Suspense>
          }
        />
      </Route>
      {/* <Route element={<PageLayout isShowFooter={false} needLinearBg={false} />}>
        <Route
          path={`/`}
          element={
            <Suspense fallback={<LoadingPage />}>
              <OtherPage />
            </Suspense>
          }
        />
      </Route> */}
      <Route path={'/test'} element={<TestPage />} />
      <Route path={'/testVideo'} element={<TestVideoPage />} />
      {/* 如果都没匹配上返回404 */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default RootComponent

// ---------------------  可能还会用到，勿删 -----------------------
// 支持的路由列表
//   const switchRouterComponent = (item: BottomNavigationRes) => {
//     switch (item.alias) {
//       case 'home':
//         return <HomePage />
//       case 'videos':
//         return <VidoesPage />
//       case 'anwang':
//         return <HomePage />
//       case 'apps':
//         return <HomePage />
//       case 'blogs':
//         return <HomePage />
//       case 'circle':
//         return <HomePage />
//       case 'bts':
//         return <HomePage />
//       case 'my':
//         return <MyPage />
//       case 'games':
//         return <HomePage />
//       case 'mine':
//         return <HomePage />
//       default:
//         return <NotFoundPage />
//     }
//   }
// 首页底部路由
//   const homeRouterList = useMemo(() => {
//     if (!bottomNavData || (bottomNavData && bottomNavData.length <= 0))
//       return []
//     return bottomNavData.map((item) => {
//       return { ...item, components: switchRouterComponent(item) }
//     })
//   }, [bottomNavData])
// 固定渲染代码中有的路由，不从接口获取-正常后端不会随意修改 alias
