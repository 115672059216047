import React from 'react'
import classnames from 'classnames'
import Footer from '@/components/footer'
import styles from './index.module.scss'
import { useOutlet } from 'react-router-dom'

interface LayoutProps {
  isShowFooter?: boolean
  needScroll?: boolean
  needLinearBg?: boolean
}

/**
 * 公共layout
 * @param LayoutProps
 * @returns Layout
 */
export default function Layout(props?: LayoutProps) {
  const { isShowFooter, needScroll, needLinearBg } = props || {
    isShowFooter: false,
    needScroll: true,
    needLinearBg: false
  }
  const children = useOutlet()

  return (
    <>
      <main
        className={classnames({
          [styles.main]: true,
          [styles.hide_footer]: !isShowFooter,
          [styles.linear_bg]: needLinearBg
        })}
        style={{ overflowY: needScroll ? 'auto' : 'hidden' }}
      >
        {children}
      </main>
      {isShowFooter && <Footer />}
    </>
  )
}
