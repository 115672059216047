import { CollectReq } from '@/interfaces/common'
import {
  HomeVideoReq,
  HomeVideoRes,
  HotSearchListRes,
  SendVideoCommentReq,
  SendVideoCommentRes,
  ShortVideoListRes,
  VideoCommentListReq,
  VideoCommentListRes,
  VideoDetailRes,
  VideoModuleListReq,
  VideoModuleListRes,
  VideoSearchReq,
  VideoSearchRes,
  UploaderPageRes,
  UploaderWorksRes,
  BuyVideoReq,
  BuyVideoRes,
  UpInfoParams,
  UpVideoParams
} from '@/interfaces/video'
import { post } from '@/utils/request'
import { getBaseReq } from '@/utils/session'
/**
 * 搜索接口
 */
export const getSearchVideo = async (
  payload: VideoSearchReq
): Promise<VideoSearchRes> => {
  const params: VideoSearchReq = {
    ...payload,
    ...getBaseReq()
  }
  return post<VideoSearchRes>('/api/v2/video/search', {
    body: JSON.stringify(params)
  })
}
// 首页视频数据
export const getHomeVideoList = async (
  payload: HomeVideoReq
): Promise<HomeVideoRes> =>
  post<HomeVideoRes>('/api/v2/video/navigation/video', {
    body: JSON.stringify(payload)
  })

// 视频详情
export const getVideoDetail = async (vid: number): Promise<VideoDetailRes> => {
  return post<VideoDetailRes>('/api/v2/video/player/desc', {
    body: JSON.stringify({
      vid,
      ...getBaseReq()
    })
  })
}
export const postCollect = async (payload: CollectReq): Promise<null> => {
  const params = {
    ...payload,
    ...getBaseReq()
  }
  return post<null>('/api/v2/video/collect', { body: JSON.stringify(params) })
}

export const getVideoCommentList = async (
  payload: VideoCommentListReq
): Promise<VideoCommentListRes> => {
  const params: VideoCommentListReq = {
    ...payload,
    ...getBaseReq()
  }

  return post<VideoCommentListRes>('/api/v2/video/get/comment', {
    body: JSON.stringify(params)
  })
}

// 视频添加评论
export const sendVideoComment = async (
  payload: SendVideoCommentReq
): Promise<SendVideoCommentRes> => {
  const params: SendVideoCommentReq = {
    ...payload,
    ...getBaseReq()
  }
  return post<SendVideoCommentRes>('/api/v2/video/add/comment', {
    body: JSON.stringify(params)
  })
}

// 视频模块列表
export const getVideoModuleList = async (
  payload: VideoModuleListReq
): Promise<VideoModuleListRes> => {
  const params: VideoModuleListReq = {
    ...payload,
    ...getBaseReq()
  }
  return post<VideoModuleListRes>('/api/v2/video/module/video/list', {
    body: JSON.stringify(params)
  })
}

// 搜索热搜接口
export const getHotSearchList = async (): Promise<HotSearchListRes> => {
  return post<HotSearchListRes>('/api/v2/video/hot/search')
}

// 短视频
export const getShortVideoList = async (): Promise<ShortVideoListRes> => {
  return post<ShortVideoListRes>('/api/v2/video/short/videos', {
    body: JSON.stringify(getBaseReq())
  })
}
// up博主信息
export const uploaderPage = async (
  data: UpInfoParams
): Promise<UploaderPageRes> => {
  const params = {
    ...data,
    ...getBaseReq()
  }
  return post<UploaderPageRes>('/api/v2/video/uploader/page', {
    body: JSON.stringify(params)
  })
}
// 1 短视频 2 长视频 3 帖子 4 种子
export const uploaderWorks = async (
  payload: UpVideoParams
): Promise<UploaderWorksRes> => {
  const params: UpVideoParams = {
    ...payload,
    ...getBaseReq()
  }
  return post<UploaderWorksRes>('/api/v2/video/uploader/works', {
    body: JSON.stringify(params)
  })
}

// 金币购买视频
export const buyVideo = async (payload: BuyVideoReq): Promise<BuyVideoRes> => {
  const params: BuyVideoReq = {
    ...payload,
    ...getBaseReq()
  }
  return post<BuyVideoRes>('/api/v2/video/buy', {
    body: JSON.stringify(params)
  })
}

// 视频播放记录
export const addVideoPlayRecord = async (id: number): Promise<any> => {
  return post<any>('/api/v2/video/add/record', {
    body: JSON.stringify({
      id,
      ...getBaseReq()
    })
  })
}
