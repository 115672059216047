import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react'
import XgPlayer, { Events, IPlayerOptions } from 'xgplayer'

import { BaseVideo } from '@/interfaces/common'
import { isBrowser } from '@/utils'
import classNames from 'classnames'
import toast from '../toast'
import { createPlayer } from './helper'
import styles from './index.module.scss'
import { addVideoPlayRecord } from '@/api/video'
import { useActivate, useUnactivate } from 'react-activation'

interface Props {
  video: BaseVideo
  className?: string

  activeIndex?: number
  currentIndex?: number

  options?: IPlayerOptions
  onLoad?: (data: any) => void
  onPlay?: () => void
  onEnd?: () => void
  onError?: (error: any) => void
  onInit?: () => void
  onCanPlay?: () => void
  onAutoPlayStarted?: (data: any) => void
  onComplete?: () => void
  onDurationchange?: () => void
}

export interface PlayerRef {
  get player(): XgPlayer
  play(): Promise<void> | null
  pause: () => void
  restart: () => void
  mute: () => void
  unmute: () => void
  onError: (error: any) => void
  getHasPause: () => boolean
  changePlayStatus: () => boolean
  onAutoPlayStarted: () => void
  onComplete?: () => void
  onDurationchange?: () => void
}

const Player = forwardRef<PlayerRef, Props>((props: Props, ref: any) => {
  const {
    video,
    onLoad,
    onPlay,
    onEnd,
    onError,
    onAutoPlayStarted,
    onInit,
    onCanPlay,
    onComplete,
    onDurationchange,
    options = {},
    className = '',
    activeIndex,
    currentIndex
  } = props
  const player = useRef<XgPlayer | null>(null)
  const videoRef = useRef<HTMLDivElement>(null)
  const activeRef = useRef<boolean>(true)

  useActivate(() => {
    activeRef.current = true
  })
  useUnactivate(() => {
    activeRef.current = false
  })

  const handlePlay = () => player.current?.play()
  const handlePause = () => player.current?.pause()
  const handleDestroy = () => player.current?.destroy()
  const handleGetHasPause = () => player.current?.paused
  const handleChangePlayStatus = () => {
    const status = handleGetHasPause()
    status ? handlePlay() : handlePause()
    return !status
  }
  const handleToggleMute = (state: boolean) => {
    if (!player.current) return
    player.current.muted = state
  }

  const handleRestart = () => {
    // 重新拉取视频资源，重新播放
    handleDestroy()
    init()
  }

  const init = async () => {
    const videoEl = videoRef.current
    if (!videoEl || !isBrowser()) return
    onInit?.()
    const playerInof = await createPlayer(video, videoEl, options)
    if (!playerInof) {
      toast('当前浏览器不支持播放')
      return
    }
    playerInof.on(Events.LOADED_DATA, (data) => {
      onLoad?.(data)
      if (
        activeIndex !== void 0 &&
        currentIndex !== void 0 &&
        activeRef.current &&
        activeIndex === currentIndex
      ) {
        data?.player?.play()
      } else if (
        activeIndex !== void 0 &&
        currentIndex !== void 0 &&
        (!activeRef.current || activeIndex !== currentIndex)
      ) {
        data?.player?.pause()
      }
    })
    onCanPlay && playerInof.on(Events.CANPLAY, onCanPlay)
    onPlay && playerInof.on(Events.PLAY, onPlay)
    onEnd && playerInof.on(Events.ENDED, onEnd)
    onError && playerInof.on(Events.ERROR, onError)
    onComplete && playerInof.on(Events.COMPLETE, onComplete)
    onDurationchange && playerInof.on(Events.DURATION_CHANGE, onDurationchange)
    // 开始播放记录播放视频ID
    playerInof.on(Events.AUTOPLAY_STARTED, (data) => {
      try {
        onAutoPlayStarted?.(data)
        addVideoPlayRecord(video.id)
      } catch (error) {
        console.error(error)
      }
    })
    playerInof.on(Events.AUTOPLAY_PREVENTED, (a) => {
      console.info('自动播放失败', a)
    })
    player.current = playerInof
  }

  useEffect(() => {
    if (video.id) {
      init()
    }
    return () => handleDestroy()
  }, [video.id])

  useEffect(() => {
    return () => handleDestroy()
  }, [])

  useImperativeHandle(ref, () => ({
    get player() {
      return player.current
    },
    play: handlePlay,
    pause: handlePause,
    restart: handleRestart,
    mute: () => handleToggleMute(true),
    unmute: () => handleToggleMute(false),
    getHasPause: handleGetHasPause,
    changePlayStatus: handleChangePlayStatus
  }))

  return (
    <div
      ref={videoRef}
      className={classNames(styles.player, 'w-full h-full', className)}
    />
  )
})

Player.displayName = 'Player'

export default Player
