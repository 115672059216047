import { Plugin, Util, Events, STATES } from 'xgplayer'
import './index.scss'
const { POSITIONS } = Plugin
// 自动播放异常，显示ICON插件
export default class AutoplayPreventedPlugin extends Plugin {
  // 插件的名称，将作为插件实例的唯一key值
  static get pluginName() {
    return 'autoplayPreventedPlugin'
  }

  constructor(args) {
    super(args)
  }

  static get defaultConfig() {
    return {
      isEndedShow: true, // 是否在播放结束之后显示
      fillMode: 'fixWidth', // 封面图填充方式，fixWidth / fixHeight / cover / contain
      position: POSITIONS.ROOT
    }
  }

  set isEndedShow(value) {
    this.config.isEndedShow = value
  }

  get isEndedShow() {
    return this.config.isEndedShow
  }

  hide() {
    Util.addClass(this.root, 'hidePlayIcon')
  }

  /**
   * @param {string} [value]
   * @returns
   */
  show() {
    Util.removeClass(this.root, 'showPlayIcon')
  }

  switchPausePlay(e) {
    const { player } = this
    e.cancelable && e.preventDefault()
    e.stopPropagation()
    if (player.state < STATES.READY) {
      return
    }
    const paused = this.player.paused
    if (!paused && player.state === STATES.RUNNING) {
      player.pause()
    } else {
      player.play()
    }
  }

  afterCreate() {
    this.on(Events.ENDED, () => {
      if (this.isEndedShow) {
        Util.removeClass(this.root, 'showPlayIcon')
      }
    })
    this.on(Events.AUTOPLAY_PREVENTED, () => {
      if (this.isEndedShow) {
        Util.addClass(this.root, 'showPlayIcon')
      }
    })

    this.on(Events.PLAY, () => {
      if (this.isEndedShow) {
        Util.removeClass(this.root, 'showPlayIcon')
      }
    })

    this.clickHandler = this.hook('startClick', this.switchPausePlay, {
      pre: (e) => {
        e.cancelable && e.preventDefault()
        e.stopPropagation()
        const { paused } = this.player
        this.emitUserAction(e, 'switch_play_pause', { props: 'paused', from: paused, to: !paused })
      }
    })

    this.bind(['click', 'touchend'], this.clickHandler)
  }

  render() {
    return `<div class="autoplay_prevented" >
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
        <g id="paly">
        <g id="Vector" opacity="0.5" filter="url(#filter0_b_4411_6875)">
        <path d="M32 0C14.3267 0 0 14.3267 0 32C0 49.6733 14.3267 64 32 64C49.6733 64 64 49.6733 64 32C64 14.3267 49.6733 0 32 0Z" fill="#121418"/>
        </g>
        <path id="Vector_2" d="M41.3801 34.7207L27.8749 45.5783C27.3809 45.8545 26.8205 46 26.2501 46C25.6796 46 25.1193 45.8546 24.6252 45.5785C24.1312 45.3023 23.7209 44.905 23.4356 44.4266C23.1503 43.9482 23.0001 43.4055 23 42.8531V21.1469C23.0001 20.5945 23.1503 20.0518 23.4356 19.5734C23.7209 19.095 24.1312 18.6977 24.6252 18.4215C25.1193 18.1454 25.6796 18 26.2501 18C26.8205 18 27.3809 18.1455 27.8749 18.4217L41.3801 29.2748C41.8728 29.5514 42.2818 29.9485 42.5661 30.4264C42.8504 30.9043 43 31.4462 43 31.9977C43 32.5493 42.8504 33.0911 42.5661 33.569C42.2818 34.0469 41.8728 34.4441 41.3801 34.7207Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_b_4411_6875" x="-6" y="-6" width="76" height="76" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="3"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4411_6875"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4411_6875" result="shape"/>
        </filter>
        </defs>
      </svg>
    </div>`
  }
}