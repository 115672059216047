import { Plugin, Util, Events } from 'xgplayer'
import ReplaySvg from '@/assets/svgs/replayPlay.svg'
const { POSITIONS } = Plugin
import './index.scss'

export default class Replay extends Plugin {
  // 插件的名称，将作为插件实例的唯一key值
  static get pluginName() {
    return 'replayPlugin'
  }
  constructor(args) {
    super(args)
  }
  static get defaultConfig() {
    return {
      disable: false,
      fillMode: 'fixWidth', // 封面图填充方式，fixWidth / fixHeight / cover / contain
      position: POSITIONS.ROOT
    }
  }

  registerIcons() {
    return {
      replay: ReplaySvg
    }
  }

  afterCreate() {
    Plugin.insert(this.icons.replay || '', this.root, 0)
    this.__handleReplay = this.hook('replayClick', () => {
      this.player.replay()
    }, {
      pre: (e) => {
        e.preventDefault()
        e.stopPropagation()
      }
    })

    this.bind('.xgplayer-replay', ['click', 'touchend'], this.__handleReplay)
    // this.bind('svg', ['click', 'touchend'], this.__handleReplay)
    // this.bind('.xgplayer-replay-txt', ['click', 'touchend'], this.__handleReplay)

    this.on(Events.ENDED, () => {
      if (!this.playerConfig.loop) {
        Util.addClass(this.player.root, 'replay')
      }
      if (this.config.disable) {
        return
      }
      this.show()
      const path = this.root.querySelector('path')
      if (path) {
        const transform = window.getComputedStyle(path).getPropertyValue('transform')
        if (typeof transform === 'string' && transform.indexOf('none') > -1) {
          return null
        } else {
          path.setAttribute('transform', transform)
        }
      }
    })

    this.on(Events.PLAY, () => {
      this.hide()
    })
  }

  handleReplay(e) {
    e.preventDefault()
    e.stopPropagation()
    this.player.replay()
    Util.removeClass(this.player.root, 'replay')
  }

  /**
   * @param {string} [value]
   * @returns
   */
  show() {
    if (this.config.disable) {
      return
    }
    this.root.style.display = 'flex'
  }

  enable() {
    this.config.disable = false
  }

  disable() {
    this.config.disable = true
    this.hide()
  }

  destroy() {
    this.unbind('.xgplayer-replay', ['click', 'touchend'], this.__handleReplay)
  }

  render() {
    return `<xg-replay class="xgplayer-replay">
      <div class="w-[64px] h-[64px] flex items-center justify-content">
        <div class="player_playIcon__KjqRj">
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="replay_plugins">
            <circle id="Ellipse 2460" cx="32" cy="32" r="32" fill="black" fill-opacity="0.62"/>
            <g id="Group 2048133898">
              <path id="Vector" d="M31.5854 14.7689C29.3288 14.7661 27.0948 15.2189 25.0173 16.1003C22.9399 16.9816 21.0618 18.2733 19.4956 19.898C19.2391 20.1636 19.0987 20.5203 19.1052 20.8895C19.1117 21.2587 19.2646 21.6102 19.5303 21.8667C19.7959 22.1231 20.1526 22.2635 20.5218 22.257C20.891 22.2505 21.2425 22.0976 21.499 21.8319C23.4421 19.8226 25.9411 18.4395 28.6756 17.8598C31.41 17.2802 34.2553 17.5304 36.8466 18.5785C39.4379 19.6266 41.6571 21.4247 43.2197 23.7423C44.7823 26.06 45.617 28.7915 45.617 31.5867C45.6387 33.4431 45.2919 35.2853 44.5965 37.0067C43.9012 38.728 42.8712 40.2943 41.5662 41.6147C40.2611 42.9351 38.7071 43.9834 36.994 44.6989C35.2809 45.4144 33.4429 45.7828 31.5864 45.7828C29.7299 45.7828 27.8918 45.4144 26.1788 44.6989C24.4657 43.9834 22.9116 42.9351 21.6066 41.6147C20.3016 40.2943 19.2715 38.728 18.5762 37.0067C17.8809 35.2853 17.534 33.4431 17.5558 31.5867C17.5558 31.2175 17.4091 30.8634 17.148 30.6023C16.887 30.3412 16.5328 30.1946 16.1636 30.1946C15.7944 30.1946 15.4403 30.3412 15.1792 30.6023C14.9181 30.8634 14.7715 31.2175 14.7715 31.5867C14.7377 33.8162 15.1477 36.0301 15.9775 38.0997C16.8073 40.1692 18.0404 42.0531 19.6051 43.6416C21.1698 45.2302 23.0347 46.4917 25.0915 47.3528C27.1482 48.2138 29.3557 48.6573 31.5854 48.6573C33.8151 48.6573 36.0226 48.2138 38.0793 47.3528C40.1361 46.4917 42.0011 45.2302 43.5657 43.6416C45.1304 42.0531 46.3635 40.1692 47.1933 38.0997C48.0232 36.0301 48.4331 33.8162 48.3994 31.5867C48.3996 29.3784 47.9649 27.1917 47.1201 25.1514C46.2752 23.1111 45.0368 21.2572 43.4755 19.6956C41.9141 18.1339 40.0605 16.895 38.0204 16.0497C35.9803 15.2044 33.7937 14.7692 31.5854 14.7689Z" fill="white"/>
              <path id="Vector_2" d="M25.607 23.1414C25.6069 23.471 25.4898 23.79 25.2765 24.0414C25.0632 24.2928 24.7676 24.4604 24.4424 24.5142L24.2149 24.5335H18.2027C17.8728 24.5333 17.5536 24.4159 17.3021 24.2023C17.0507 23.9886 16.8833 23.6926 16.8298 23.367L16.8106 23.1414V17.1254C16.8089 16.7746 16.9397 16.4361 17.1769 16.1776C17.414 15.9192 17.74 15.7597 18.0896 15.7312C18.4392 15.7027 18.7867 15.8072 19.0626 16.0239C19.3384 16.2405 19.5223 16.5534 19.5775 16.8998L19.5968 17.1254V21.7473H24.2168C24.911 21.7473 25.4836 22.2544 25.5916 22.9158L25.6109 23.1414H25.607Z" fill="white"/>
              <path id="Vector_3" d="M37.0398 33.1687L30.7596 37.563C30.4706 37.7651 30.1316 37.8841 29.7797 37.9069C29.4277 37.9298 29.0762 37.8556 28.7634 37.6926C28.4507 37.5296 28.1886 37.2839 28.0058 36.9822C27.823 36.6806 27.7264 36.3346 27.7266 35.9819V27.1932C27.7264 26.8405 27.823 26.4945 28.0058 26.1928C28.1886 25.8912 28.4507 25.6455 28.7634 25.4825C29.0762 25.3194 29.4277 25.2453 29.7797 25.2681C30.1316 25.291 30.4706 25.4099 30.7596 25.612L37.0398 30.0083C37.2936 30.1862 37.5008 30.4225 37.6439 30.6974C37.7869 30.9723 37.8616 31.2776 37.8616 31.5875C37.8616 31.8974 37.7869 32.2028 37.6439 32.4777C37.5008 32.7526 37.2936 32.9889 37.0398 33.1667V33.1687Z" fill="white"/>
            </g>
            </g>
          </svg>
        </div>
      </div>
    </xg-replay>`
  }
}